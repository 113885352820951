import React, { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { Box, Button, Container, Grid } from 'theme-ui';
import BlockTitle from 'components/block-title';
import { Link } from 'components/link';
import TemplateCard from 'components/cards/template-card';
import { AiFillFunnelPlot } from "react-icons/ai";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import {
  IoLogoTwitter,
  IoLogoTiktok,
  IoLogoYoutube,
} from "react-icons/io5";
import InstagramLogo from "assets/images/templates/instagramLogo.png";

const TEMPLATES_DATA = [
  {
    image: <HiChatBubbleBottomCenterText size="3rem"/>,
    text:
      "Create compelling product descriptions to sell your products.",
    textEs:
      "Crea descripciones de producto convincentes para vender tus productos.",
    heading: "Product Description",
    headingEs: "Descripción de producto",
  },
  {
    image: <IoLogoTiktok size="3rem" color="black"/>,
    heading: "TikTok Caption with Hashtags",
    headingEs: "Captions de TikTok con Hashtags",
    text: "Elevate your TikTok content with captions that captivate your audience.",
    textEs: "Eleva tu contenido de TikTok con captions que cautiven a tu audiencia.",
  },
  {
    image: <IoLogoYoutube size="3rem" color="red"/>,
    text:
      "Unleash eye-catching titles that'll skyrocket your click-through rate to infinity and beyond.",
    textEs:
      "Crea títulos que capturen la atención de la gente y manden tu tasa de clics al infinito y más alla.",
    heading: 'Video Title',
    headingEs: 'Título de Vídeo',
  },
  {
    image: <IoLogoTwitter size="3rem" color="#1D9BF0"/>,
    text:
      '',
    heading: "Tweet Thread",
    headingEs: "Hilo de Tuits",
    text: "Cover any topic with ease and be the expert your followers need.",
    textEs: "Cubre cualquier tema con facilidad y sé el experto que tus seguidores necesitan.",
  },
  {
    image: <img src={InstagramLogo}/>,
    heading: "Instagram Caption with Hashtags",
    headingEs: "Caption de Instagram con Hashtags",
    text: "Create catchy Instagram captions that spark engagement.",
    textEs: "Crea captions de Instagram llamativos que generen interacción."
  },
  {
    image: <AiFillFunnelPlot size="3rem" color="purple"/>,
    heading: "AIDA Framework",
    headingEs: "Modelo AIDA",
    text: "Generate a mighty message leveraging the renowned marketing framework: Attention, Interest, Desire, Action (AIDA).",
    textEs: "Genera un potente mensaje aprovechando el conocido modelo de marketing: Atención, Interés, Deseo, Acción (AIDA)",
  },
];

const Templates = ({templateData}) => {
  const { language, templatesPage } = useContext(AppContext);
  const es = language == "es";

  let title;
  //if (templatesPage)
    //title = es ? "Herramientas de Escritura (Más muy pronto)" : "Writing Tools (More coming soon)"
  //else
  title = es ? "Herramientas de Escritura" : "Writing Tools";

  return (
    <Box as="section" id="templates" sx={templatesPage ? styles.templatesPage: styles.templates}>
      <Container>
        <BlockTitle
          title={title}
          text={es ? "Desbloquea el poder de las palabras con la IA" : "Unlock the power of words with AI"}
        />
        <Box sx={styles.container}>
          {(templateData || TEMPLATES_DATA).map(({ image, text, textEs, heading, headingEs }, index) => (
            <TemplateCard
              image={image}
              text={es ? textEs || text : text}
              heading={ es ? headingEs || heading : heading}
              key={index}
            />
          ))}
        </Box>
        { !templatesPage &&
        <Box sx={styles.btnContainer}>
          <Link
            path={`/${es ? "es/" : ""}tools`}
            label={
              <Button>
                {es ? "Ver todas las herramientas de escritura": "See all writing tools"}
              </Button>
            }
          />
        </Box>
        }
      </Container>
    </Box>
  );
};

export default Templates;

const styles = {
  btnContainer: {
    pt: "10px",
    textAlign: "center",
  },
  templates: {
    py: ['80px', null, null, null, '80px', '100px', '140px'],
  },
  templatesPage: {
    py: ['100px', null, null, null, '100px', '120px', '160px'],
  },
  container: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "780px",
    mx: "auto",
  },
  themeColorIcon: {
    color: "primary"
  }
};