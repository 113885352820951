import React from "react";
/** @jsx jsx */
import { jsx } from 'theme-ui';

const TemplateCard = ({ image, text, heading }) => {
  return (<>
    <div sx={styles.card}>
      {image}
      <span sx={styles.title}>
        {heading}
      </span>
      <span sx={styles.description}>
        {text}
      </span>
    </div>
  </>);
};

export default TemplateCard;

const styles = {
  card: {
    lineHeight: "initial",
    display: "inline-block",
    textAlign: "center",
    margin: "0 5px 10px",
    width: "250px",
    verticalAlign: "top",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    p: "1rem",
  },
  title: {
    fontWeight: "bold",
    display: "block",
    color: "rgba(0, 0, 0, 0.87)",
  },
  description: {
    fontSize: "0.9rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
};
