export function getBrowserLang() {
  if (typeof window === "undefined" || !window.navigator) {
    return "en";
  }

  const lang =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage ||
    window.navigator.systemLanguage ||
    "en"

  return lang;
}

export function redirectIfSpanish(path, search) {
  let redirect = false;
  const lang = getBrowserLang();
  let localS;
  if (typeof window !== "undefined") {
    localS = window.localStorage;
  }
  if (lang.indexOf("es") == 0) {
    if (!(localS && localS.getItem("uiLang"))) {
      window.location.replace(`/es${path}${search || ""}`);
      redirect = true;
    }

    if (localS) {
      localS.setItem("uiLang", "es")
    }
  } else if (localS) {
    localS.setItem("uiLang", lang)
  }

  return redirect;
}