import React from 'react';
import SEO from '../components/seo';
import TemplatesPage from '../pagesComponents/TemplatesPage';
import { redirectIfSpanish } from '../utils';

export default function Dg24Page({path, location = {}}) {
  if (redirectIfSpanish(path, location.search))
    return <SEO title="Writing Tools" lang="en"/>
  
  return (
    <TemplatesPage appContextValue={{language: "en", path, templatesPage: true}} />
  );
}
