import React, { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { StickyProvider } from 'contexts/sticky/sticky.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Templates from 'sections/templates';
import { AiFillFunnelPlot } from "react-icons/ai";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import { MdEditDocument } from "react-icons/md";
import {
  IoLogoTwitter,
  IoLogoTiktok,
  IoLogoYoutube,
} from "react-icons/io5";
import InstagramLogo from "assets/images/templates/instagramLogo.png";

import 'rc-drawer/assets/index.css';
import 'typeface-dm-sans';

const TEMPLATES_DATA = [
  {
    image: <HiChatBubbleBottomCenterText size="3rem"/>,
    text:
      "Create compelling product descriptions to sell your products.",
    textEs:
      "Crea descripciones de producto convincentes para vender tus productos.",
    heading: "Product Description",
    headingEs: "Descripción de producto",
  },
  {
    image: <AiFillFunnelPlot size="3rem" color="purple"/>,
    heading: "AIDA Framework",
    headingEs: "Modelo AIDA",
    text: "Generate a mighty message leveraging the renowned marketing framework: Attention, Interest, Desire, Action (AIDA).",
    textEs: "Genera un potente mensaje aprovechando el conocido modelo de marketing: Atención, Interés, Deseo, Acción (AIDA)",
  },
  {
    image: <MdEditDocument size="3rem" color="purple"/>,
    heading: "PAS Framework",
    headingEs: "Modelo PAS",
    text: "Create compelling copy that employs the Problem Agitate Solution marketing model to trigger greater conversions.",
    textEs: "Crea copy convincente que emplee el modelo Problema Agitación Solución para desatar mayores conversiones.",
  },
  {
    image: <IoLogoTiktok size="3rem" color="black"/>,
    heading: "TikTok Caption with Hashtags",
    headingEs: "Captions de TikTok con Hashtags",
    text: "Elevate your TikTok content with captions that captivate your audience.",
    textEs: "Eleva tu contenido de TikTok con captions que cautiven a tu audiencia.",
  },
  {
    image: <IoLogoYoutube size="3rem" color="red"/>,
    text:
      "Unleash eye-catching titles that'll skyrocket your click-through rate to infinity and beyond.",
    textEs:
      "Crea títulos que capturen la atención de la gente y manden tu tasa de clics al infinito y más alla.",
    heading: 'Video Title',
    headingEs: 'Título de Vídeo',
  },
  {
    image: <IoLogoTwitter size="3rem" color="#1D9BF0"/>,
    heading: "Tweet Thread",
    headingEs: "Hilo de Tuits",
    text: "Cover any topic with ease and be the expert your followers need.",
    textEs: "Cubre cualquier tema con facilidad y sé el experto que tus seguidores necesitan.",
  },
  {
    image: <IoLogoTwitter size="3rem" color="#1D9BF0"/>,
    heading: "Tweet",
    headingEs: "Tuit",
    text: "Create compelling, scroll stopping tweet threads that captivate your audience.",
    textEs: "Crea hilos de tuits convincentes, que detengan el scroll y cautiven a tu audiencia.",
  },
  {
    image: <img src={InstagramLogo}/>,
    heading: "Instagram Caption with Hashtags",
    headingEs: "Caption de Instagram con Hashtags",
    text: "Create catchy Instagram captions that spark engagement.",
    textEs: "Crea captions de Instagram llamativos que generen interacción."
  },
];

export default function HomePage({appContextValue}) {
  const { language } = useContext(AppContext);
  const es = language == "es";

  return (
    <AppContext.Provider value={appContextValue}>
      <StickyProvider>
        <Layout>
          <SEO title={es ? "Herramientas de Escritura" : "Writing Tools"} lang={appContextValue?.language}/>
          <Templates templateData={TEMPLATES_DATA}/>
        </Layout>
      </StickyProvider>
    </AppContext.Provider>
  );
}
